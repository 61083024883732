<!--
 * @Autor: oops.liu
今日上新
-->
<template>
  <div class="exclusive-box">
    <!-- 选择区 -->

    <div class="box">
      <div class="check-domain">
        <div class="page_tabs">
          <div class="tabs-left">
            <van-tabs
              v-model="active"
              color="#F02B2B"
              background="transparent"
              title-inactive-color="#333"
              title-active-color="#F02B2B"
              line-height="2"
              line-width="33"
              :swipeable="true"
              @change="tabChange"
            >
              <van-tab
                v-for="item in tabList"
                :key="item.id + 'a'"
                :title="item.name"
                :name="item.id"
              />
            </van-tabs>
          </div>
          <div class="tabs-right" @click="openTabHandle">
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="order-list">
          <div class="order-left">
            <order-bar @change="orderChange" v-model="orderActive" />
          </div>
          <div class="order-right" @click="checkLineHandle">
            <van-icon v-if="line" :name="card" />
            <van-icon v-else-if="!line" :name="list" />
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-box">
      <scroller
        ref="my_scroll"
        :noDataText="noDataText"
        :on-infinite="infinite"
        :on-refresh="refresh"
      >
        <div class="exclusive">
          <!-- 商品区 -->
          <div class="goods-list flex_sb">
            <template v-if="!line">
              <goods-item
                v-for="(item, index) in listData"
                :key="index + 'b'"
                :info="item"
                @jump="handleGoods(item)"
                height="172px"
              />
            </template>
            <template v-else-if="line">
              <goods-line-item
                v-for="(item, index) in listData"
                :key="index + 'c'"
                :info="item"
                @jump="handleGoods(item)"
              />
            </template>
          </div>
        </div>
      </scroller>
    </div>

    <div class="pop-box">
      <van-popup
        v-model="showTab"
        position="top"
        closeable
        close-icon="arrow-up"
        :style="{ height: '40%' }"
      >
        <div class="pop-info">
          <div class="top">
            <span>选择商品分类</span>
          </div>
          <div class="pop-content">
            <div
              class="tab-item"
              v-for="item in tabList"
              :key="item.id + 'd'"
              @click="tabChangeHandle(item)"
            >
              <div class="item" :class="{ 'active-item': active === item.id }">
                {{ item.name | lengthFilter }}
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import orderBar from "@/components/goods//orderBar.vue";
import goodsItem from "@/components/goods//goodsItem.vue";
import goodsLineItem from "@/components/goods/goodsLineItem.vue";
export default {
  components: { orderBar, goodsItem, goodsLineItem },
  mixins: [scrollMethods, handleGoods],
  props: {},
  data() {
    return {
      tabList: [],
      line: false,
      time: 10 * 60 * 60 * 1000,
      seller: {
        id: 1,
        bg: require("@/assets/img/bg.png"),
        logo: require("@/assets/img/logo.png"),
        hint: "满300减30",
        sellerName: "梵希蔓服饰品牌专场",
        time: new Date(),
      },
      active: "",
      cat: 1,
      showTab: false,
      card: require("@/assets/img/card_icon.png"),
      list: require("@/assets/img/list_icon.png"),
      sortColumn: "",
      isAsc: false,
      asc: true,
      showTop: false,
      orderActive: 1,
    };
  },
  computed: {
    ...mapState({
      goods: (state) => state.app.goods,
    }),
  },
  methods: {
    ...mapActions({
      load: "active/getMallGoodsToMallFirst",
      cateLoad: "active/getMallFirst",
    }),
    async loadInfo(isRefresh = false) {
      const params = {
        current: this.pages,
        categoryFirst: this.active,
        isHot: 1,
        sortColumn: this.sortColumn,
        asc: this.asc,
      };

      if (!this.sortColumn) {
        delete params.sortColumn;
      }
      if (!this.isAsc) {
        delete params.asc;
      }
      const data = await this.load({ ...params });
      this.dataLength = data.records ? data.records.length : 0;
      if (isRefresh) {
        this.listData = data.records;
      } else {
        this.listData = this.listData.concat(data.records);
      }
      return data;
    },

    openTabHandle() {
      this.showTab = true;
    },
    tabChange(id, title) {
      this.pages = 1;
      this.listData = [];
      (this.active = id), this.loadInfo();
    },
    tabChangeHandle(item) {
      this.active = item.id;
      this.showTab = false;
      this.pages = 1;
      this.listData = [];
      this.loadInfo();
    },
    order(item) {
      this.cat = item.active;
    },
    orderChange(item) {
      this.orderActive = item.active;
      this.pages = 1;
      this.sortColumn = item.sortColumn;
      this.listData = [];
      if (item.sortColumn === "price_down") {
        this.isAsc = true;
        this.asc = item.asc;
      } else {
        this.isAsc = false;
      }
      if (item.sortColumn === "isHot") {
        this.isHot = true;
      } else {
        this.isHot = false;
      }
      this.loadInfo();
    },
    checkLineHandle() {
      this.line = !this.line;
    },
  },
  created() {
    this.cateLoad().then((list) => {
      this.tabList = list;
      this.active = this.tabList[0].id;
      if (this.active) {
        this.loadInfo();
      }
    });
  },
  filters: {
    lengthFilter(string) {
      let str;
      if (string.length > 5) {
        str = string.substring(0, 4) + "...";
      } else {
        str = string;
      }
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
